import { Link, Outlet } from "@remix-run/react";
import { $path } from "remix-routes";

export const AuthHeader = () => {
	return (
		<div>
			<div className="w-full border-b">
				<div className="flex h-14 items-center justify-center w-56">
					<Link
						to={$path("/")}
						className="flex items-center gap-2 font-semibold"
					>
						<img
							className="w-40"
							src="/images/AI-KIJI-MOJI-KUN.png"
							alt="APP LOGO"
						/>
					</Link>
				</div>
			</div>
			<Outlet />
		</div>
	);
};

export default AuthHeader;
